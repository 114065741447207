import './App.css';
import { IoIosCall, IoIosArrowDown } from 'react-icons/io';
import { carousels, faqs, introduce, opinions, steps } from './datas';
import { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCube,
  Autoplay,
} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/bundle';

function App() {
  let init = {};
  for (let index = 0; index < carousels.length; index++) {
    init = { index: false };
  }
  const [carousel, setCarousel] = useState({ ...init, 0: true });
  const goNext = (i) => {
    let key;
    if (i + 1 < carousels.length) {
      key = i + 1;
    } else {
      key = 0;
    }
    const newinit = init;
    newinit[key] = true;
    setCarousel(newinit);
  };

  let faqinit = {};
  for (let index = 0; index < carousels.length; index++) {
    faqinit = { index: false };
  }
  const [faq, setFaq] = useState(faqinit);
  const handleOpen = (i) => {
    const newinit = init;
    if (!faq[i]) {
      newinit[i] = true;
    }
    setFaq(newinit);
  };

  const [moveto, setMoveto] = useState(0);
  const handleMove = () => {
    let newMove;
    if (moveto < 40) {
      newMove = moveto + 20;
    } else {
      newMove = 0;
    }
    setMoveto(newMove);
  };

  const navRefs = {
    nav01: useRef(),
    nav02: useRef(),
    nav03: useRef(),
    nav04: useRef(),
  };
  const btnRef = useRef();
  const [navFlag, setNavFlag] = useState([1, 0, 0, 0]);
  const [btnFlag, setBtnFlag] = useState(false);
  const handleScroll = () => {
    const newRects = [
      navRefs.nav01.current.getBoundingClientRect(),
      navRefs.nav02.current.getBoundingClientRect(),
      navRefs.nav03.current.getBoundingClientRect(),
      navRefs.nav04.current.getBoundingClientRect(),
    ];

    const isIn = newRects.map(
      (element) => element.top < 60 && -element.top < element.height - 60
    );
    if (!isIn[0] && !isIn[1] && !isIn[2] && !isIn[3]) {
      setNavFlag([1, 0, 0, 0]);
    } else {
      setNavFlag(isIn);
    }

    const posiBtn = btnRef.current.getBoundingClientRect();
    if (posiBtn.bottom < 60) {
      setBtnFlag(true);
    } else {
      setBtnFlag(false);
    }
    console.log(btnFlag);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [swipeCardStyle, setSwipeCardStyle] = useState({});
  const updateStyle = () => {
    const width = window.innerWidth;
    // 화면 가로 길이에 따라 rem 값 계산
    if (width >= 360 && width <= 480) {
      const remValue = 18 + ((width-360) * (7 / 120));
      setSwipeCardStyle({
        'width': `${remValue}rem`,
      });
    } else if (width > 480) {
      setSwipeCardStyle({
        'width': `${25}rem`,
      });
    } else {
      setSwipeCardStyle({
        'width': `${18}rem`,
      });
    }
  };
  useEffect(() => {
    // 초기 스타일 업데이트
    updateStyle();
    // 창 크기 변경 시 스타일 업데이트
    window.addEventListener('resize', updateStyle);
    return () => window.removeEventListener('resize', updateStyle);
  }, []);

  return (
    <div className="App">
      <div className="contents relative">
        <div className="header">
          <div className="logo">
            <a href=".App">
              <img src="/logo-small.png" alt="small logo" />
            </a>
          </div>
          <div className="nav-bar">
            <a
              href="#nav01"
              className={navFlag[0] ? 'nav-menu-on' : 'nav-menu'}
            >
              서비스 소개
            </a>
            <a
              href="#nav02"
              className={navFlag[1] ? 'nav-menu-on' : 'nav-menu'}
            >
              사용 안내
            </a>
            <a
              href="#nav03"
              className={navFlag[2] ? 'nav-menu-on' : 'nav-menu'}
            >
              만남 후기
            </a>
            <a
              href="#nav04"
              className={navFlag[3] ? 'nav-menu-on' : 'nav-menu'}
            >
              회사 소개
            </a>
          </div>
        </div>
        <div className="banner">
          <img src="/imgs/banner-img.png" alt="banner-img" />
          <div className="banner-shadow">
            <div>
              <p className="subtitle">
                4060을 위한 검증된 <br />
                이성 친구 만남 서비스
              </p>
              <img src="logo-big.png" alt="logo" />
            </div>
            <div className="btns" ref={btnRef}>
              <div className="event-texts">지금 신청하고 무료로 소개받기</div>
              <a className="find-btn" href="https://smore.im/form/QCja5ZakX4">
                이상형 찾기 시작
              </a>
              <a className="take-a-look-btn" href="#nav00">
                <p>자세히 보기</p>
                <img src="/arrow.png" alt="arrow" className="icons" />
              </a>
            </div>
          </div>
        </div>
        <div id="nav00"></div>
        <div className="sections relative">
          <div className={btnFlag ? 'float-btns' : 'none'}>
            <a className="find-btn" href="https://smore.im/form/QCja5ZakX4">
              이상형 찾기 시작
            </a>
            <a className="call-btn" href="tel:010-3237-4502">
              <IoIosCall className="icon-call" />
            </a>
          </div>
          <div ref={navRefs.nav01}>
            <div id="section01" className="section">
              <p className="section-title">고객의 소리</p>
              <div className="section-desc">
                명품인연은 실제 고객의 어떤 문제를 <br />
                해결하고자 하나요?
              </div>

              {opinions.map((people) => (
                <div className="opin-card">
                  <div className="profiles">
                    <img src={people.img} alt="" className="profile-pic" />
                    <div className="profile">
                      <p className="profile-name">{people.name}</p>
                      <p>{people.age}</p>
                      <p>{people.tags}</p>
                    </div>
                  </div>
                  {people.text}
                </div>
              ))}

              <div id="nav01"></div>
            </div>

            <div id="section02" className="section">
              <p className="section-title">명품인연 솔루션</p>
              <div className="section-desc">
                이상형 매칭부터 안전한 만남까지 <br />
                책임져주는 소개팅, 미팅 서비스
              </div>
              <img src="/imgs/solution.png" alt="solution" />{' '}
              <div id="nav02"></div>
            </div>
          </div>
          <div ref={navRefs.nav02}>
            <div id="section03" className="section">
              <p className="section-title">사용 안내</p>
              <div className="section-desc">명품 인연을 만나기까지의 과정</div>
              <div className="section-box3">
                {steps.map((step) => (
                  <div className="stepcard">
                    <div className="dotted-line"></div>
                    <div className="circle">{step.no}</div>
                    <div className="step-innercard">
                      <p className="step-title">{step.title}</p>
                      <p>{step.text}</p>
                      <img src={step.img} alt="step img" />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div id="section04" className="section">
              <p className="section-title">명품인연 차별점</p>
              <div className="section-desc">
                고객분들의 필수적인 검증부터,
                <br /> 안전한 만남까지 책임집니다
              </div>
              <div>
                명품인연은 고객분들의 만족과 안전을 위하여 끊임없이 노력하고
                있습니다
              </div>
              <img src="/imgs/diff.png" alt="diff" />
              <div id="nav03"></div>
            </div>
          </div>
          <div id="section05" className="section relative" ref={navRefs.nav03}>
            <p className="section-title">실제 신청 후기</p>
            <div className="section-desc ">
              명품인연이 이어준 소중한 인연을 <br />
              소개합니다
            </div>
            <Swiper
              className="swiper-init"
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              slidesPerView={1.1}
              navigation
              pagination={{ clickable: true }}
              // scrollbar={{ draggable: true }}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log('slide change')}
              style={{
                '--swiper-pagination-color': '#cf9f6f',

                '--swiper-navigation-color': 'grey',
                '--swiper-navigation-size': '2rem',
              }}
            >
              {introduce.map((card) => (
                <SwiperSlide>
                  <div className="intro-card" style={swipeCardStyle}>
                    <div>
                      <img src={card.img} alt={card.who} />
                      <p className="fivestar">★★★★★</p>
                      <p className="intro-text">"{card.text}"</p>
                    </div>
                    <p>{card.who}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="box5">
              <div
                className="section-box5"
                style={{ marginLeft: `-${moveto}rem` }}
              ></div>
            </div>
            <div id="nav04"></div>
          </div>
          <div ref={navRefs.nav04}>
            <div id="section06" className="section">
              <p className="section-title">명품인연 미션</p>
              <div className="section-desc">
                가치 있는 삶에 설렘과 행복을 더해 <br /> 건강한 사회를 만듭니다
              </div>
              <div className="info-card">
                <p className="info-title">목표</p>
                <p>
                  명품인연은 시간이 지날수록 심해지는 외로움과 사회적 관계
                  단절의 문제를 품격과 기술로서 해결하고자 출발했습니다.
                </p>
              </div>
              <iframe
                src="https://www.youtube.com/embed/5Yt2YgmoMo4?si=-c5Oinvkh0gGhndb"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
              <div className="info-card">
                <p className="info-title">팀원 구성</p>
                <div className="logobox">
                  <img src="/imgs/logos.png" alt="logos" />
                </div>
                <p>
                  서울대학교 창업동아리(SNUSV) 운영진 경험과 SeniorFuture회장
                  출신 대표자와 서울대학교 법학전문대학원, 석사, 박사과정을
                  졸업한 팀원과 SeniorFuture팀원들로 구성되어 있습니다
                </p>
              </div>
              <div className="info-card">
                <p className="info-title">대표자의 Seniorfuture 란?</p>
                <Swiper
                  className="swiper-init"
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  slidesPerView={1}
                  navigation
                  pagination={{ clickable: true }}
                  onSwiper={(swiper) => console.log(swiper)}
                  onSlideChange={() => console.log('slide change')}
                  style={{
                    '--swiper-pagination-color': '#cf9f6f',

                    '--swiper-navigation-color': 'grey',
                    '--swiper-navigation-size': '2rem',
                  }}
                  loop={true}
                >
                  {carousels.map((imgtxt, i) => (
                    <SwiperSlide>
                      <div className="carousel">
                        <img src={imgtxt} alt="swiperimg"></img>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <p>
                  대표자가 만든 인구구조의 변화에 따른 문제를 해결하기 위해 만든
                  국내 최초의 시니어 이슈 분석 학회입니다. 서울대학교 김난도
                  교수를 포함하여 학교, 언론, 기업, 지자체 등 에서 많은 관심과
                  도움을 주고 계십니다.
                </p>
              </div>
            </div>
            <div id="section07" className="section">
              <div className="section-desc">자주 묻는 질문</div>
              <div>
                {faqs.map((q, i) => (
                  <div className="faq-card">
                    <div onClick={() => handleOpen(i)} className="question">
                      <p>Q. {q.q}</p>
                      <IoIosArrowDown
                        className={faq[i] ? 'upsidedown' : 'arrowdown'}
                      />
                    </div>
                    <div className={faq[i] ? 'answer' : 'none'}>
                      <p>A. {q.a}</p>
                      <p>{q.b}</p>
                      <p>{q.c}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div id="section08" className="section">
            <p>
              <span> 회사명 </span>
              명품인연
            </p>
            <p>
              <span> 주소 </span>서울특별시 관악구 관악로1
            </p>
            <p>
              <span> 전화 </span>010-3237-4502
            </p>
            <p>
              <span> 이메일 </span>myeongpuminyeon@gmail.com
            </p>
            <div>
              <p>이용약관</p>
              <p>개인정보처리방침</p>
              <p>고객센터</p>
              <p>인재영입</p>
            </div>
          </div>
          <div className="bottom-spacer"></div>
        </div>
      </div>
    </div>
  );
}

export default App;
