export const opinions = [
  {
    img: '/imgs/opinions/1.png',
    name: '정*호',
    age: '48세 / 회계사 / 이혼',
    tags: '#연애 #테니스 #무교',
    text: (
      <p>
        “이성을 만나는데 있어서 아무나 만나고 싶지 않고
        <b>나와 비슷하고 검증이 된 사람</b>을 만나고 싶어요”
      </p>
    ),
  },
  {
    img: '/imgs/opinions/2.png',
    name: '김*미',
    age: '54세 / OO대 부교수 / 미혼',
    tags: '#결혼 #골프 #불교',
    text: (
      <p>
        “맘이 잘맞는 사람을 만나고 싶어서 이것 저것 앱을 써봤는데, 직접 만나려고
        하면
        <b>불안한 마음을 지울 수는 없는 것 같아요”</b>
      </p>
    ),
  },
  {
    img: '/imgs/opinions/3.png',
    name: '최*석',
    age: '58세 / OO전자 과장 / 사별',
    tags: '#연애 #등산 #기독교',
    text: (
      <p>
        “다른 소개팅 서비스를 써봤는데, 매칭만 해주고 끝이더라고요.
        <b> 어디서 언제 만날지 제가 다 리드해야 해서 애좀 먹었습니다 </b>”
      </p>
    ),
  },
  {
    img: '/imgs/opinions/4.png',
    name: '고*현',
    age: '50세 / 공무원 / 이혼',
    tags: '#친구 #베이킹 #천주교',
    text: (
      <p>
        “ 모임이나 동호회는 저랑 목적이 같지 않고 <b>적당한 가격</b>에 결혼은
        아니지만 <b>가볍지도 않은 만남을 갖고 싶어요</b>”
      </p>
    ),
  },
];
export const steps = [
  {
    no: '1',
    title: '이상형 질의응답',
    text: '원하시는 이상형을 찾아드리기 위해서 16가지 간단한 질문에 답해주세요',
    img: '/imgs/steps/1.png',
  },
  {
    no: '2',
    title: '일정 조율',
    text: '명품인연 매니저가 이상형과 만날 장소와 시간을 조율해 드립니다',
    img: '/imgs/steps/2.png',
  },
  {
    no: '3',
    title: '50분 대화',
    text: '서로를 알아가기 위한 50분 대화를 진행합니다',
    img: '/imgs/steps/3.png',
  },
  {
    no: '4',
    title: 'O, X 선택',
    text: '50분의 대화가 끝나고 두 분 모두 O를 선택했다면 연락처가 공개됩니다',
    img: '/imgs/steps/4.png',
  },
];
export const introduce = [
  {
    img: '/imgs/introduce/1.png',
    text: '이렇게 좋은 만남을 주선해주셔서 감사합니다. 둘 다 미술에 관심이 많아 전시회를 함께 가기로 했어요.',
    who: '정*늘/51세/디자이너',
  },
  {
    img: '/imgs/introduce/2.png',
    text: '오랜만에 설레는 마음으로 만났습니다. 생각지도 못한 공통점이 많아 대화가 즐거웠습니다. 함께 등산을 즐길 수 있어 좋았어요',
    who: '김*수/52세/은퇴공무원',
  },
  {
    img: '/imgs/introduce/3.png',
    text: '이렇게 좋은 만남을 주선해주셔서 감사합니다. 마음이 잘 통하는 사람을 만나 기쁩니다. 이젠 외로움을 덜 수 있을 것 같아요.',
    who: '이*민/54세/회사원',
  },
];
export const carousels = [
  '/imgs/carousels/1.png',
  '/imgs/carousels/2.png',
  '/imgs/carousels/3.png',
  '/imgs/carousels/4.png',
];
export const faqs = [
  {
    q: ' 어떻게 신청하나요?',
    a: '\'이상형 찾기 시작\' 버튼을 클릭하시고, 설문을 완료하시면 신청이 완료됩니다!',
  },
  {
    q: '이상한 사람이 나올까 걱정돼요',
    a: '매니저가 상대방의 신분증과 사진을 직접 확인한 후 매칭 해드립니다.',
  },
  {
    q: '제 사진과 정보가 노출 될까 걱정돼요',
    a: '보내주신 사진은 소중한 개인정보이기 때문에 흐리게 블러처리를 해드립니다!',
    b: ' 개인 정보 및 인증을 위한 자료는 오로지 인증 목적으로만 사용되며, 외부에 공개되지 않습니다. ',
    c: ' 타인의 인증 자료를 도용한 사실이 발견될 경우, 즉시 탈퇴 및 법률적 조치가 따릅니다. ',
  },
  {
    q: '매칭 상대는 어떻게 확인하나요?',
    a: '이상형 매칭이 완료된 후  담당 매니저가 전화 및 문자로 상대분의 프로필 카드를 보내드려요.',
  },
  {
    q: '상대가 안나오면 어떻게 해요?',
    a: '상대분이 만남 전 48시간 내 약속을 취소하거나 당일 노쇼 시, 고객님과 상대분의 참여비 합산 전액을 포인트로 지급해드려요.',
  },
  {
    q: '가격은 얼마인가요?',
    a: '두가지 플랜이 있어요!',
    b: ' 만남 1회: 5만원 ',
    c: ' 2개월 동안 무제한 만남: 30만원 ',
  },
  {
    q: '명품인연 앱은 언제 출시 되나요?',
    a: '24년 10월! 앱이 출시될 예정입니다!',
  },
  {
    q: '앱 출시 이전에도 이용할 수 있나요?',
    a: '앱 출시 이전에도 현재 보고 계시는 웹사이트를 통해서 서비스를 동일하게 사용하실 수 있습니다!',
  },
];
